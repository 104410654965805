
import { sample } from "lodash";
import bscIcon from "../assets/images/bsc.png";
import ethIcon from "../assets/images/eth.png";
import arbIcon from "../assets/images/arb.png";
import deriwIcon from "../assets/images/deriw-logo-white.png";
import network from "../assets/images/network.png";
import config from "../config";
import { parseEther } from "ethers/lib/utils";



export const MAINNET = 1;
export const TESTNET = 11155111;
export const ARBITRUM = 42161
export const ARBITRUMSEPOLIA = 421614
export const DERIWDEVNET = 35318034165;
export const DERIWTESTNET = 35318034165;

// TODO take it from web3
export let DEFAULT_CHAIN_ID = DERIWTESTNET;
if (config.isDevelopment()) {
  DEFAULT_CHAIN_ID = DERIWDEVNET;
} else if (config.isTestnet()) {
  DEFAULT_CHAIN_ID = DERIWTESTNET;
} else {
  DEFAULT_CHAIN_ID = DERIWTESTNET;
}

export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS = [];

if (config.isDevelopment()) {
  SUPPORTED_CHAIN_IDS.push(DERIWDEVNET);
} else if (config.isTestnet()) {
  SUPPORTED_CHAIN_IDS.push(DERIWTESTNET);
} else {
  SUPPORTED_CHAIN_IDS.push(DERIWTESTNET);
}

export const IS_NETWORK_DISABLED = {
  [MAINNET]: false,
  [TESTNET]: false,
  [ARBITRUM]: false,
  [ARBITRUMSEPOLIA]: false,
  [DERIWDEVNET]: false,
  [DERIWTESTNET]: false,
};

export const CHAIN_NAMES_MAP = {
  [MAINNET]: "Ethereum",
  [TESTNET]: "Sepolia",
  [ARBITRUM]: "Arbitrum",
  [ARBITRUMSEPOLIA]: "Arbitrum Sepolia",
  [DERIWDEVNET]: "Deriw Testnet",
  [DERIWTESTNET]: "Deriw Testnet",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [MAINNET]: "0",
  [TESTNET]: "0",
  [ARBITRUM]: "0",
  [ARBITRUMSEPOLIA]: "0",
  [DERIWDEVNET]: "3500000000",
  [DERIWTESTNET]: "3500000000",
};

export const MAX_GAS_PRICE_MAP = {
  [MAINNET]: "200000000000", // 200 gwei 5000000000
  [TESTNET]: "200000000000",
  [ARBITRUM]: "200000000000",
  [ARBITRUMSEPOLIA]: "200000000000",
  [DERIWDEVNET]: "200000000000",
  [DERIWTESTNET]: "200000000000"
};

export const HIGH_EXECUTION_FEES_MAP = {
  [MAINNET]: 3, // 3 USD
  [TESTNET]: 3, // 3 USD
  [ARBITRUM]: 3,
  [ARBITRUMSEPOLIA]: 3,
  [DERIWDEVNET]: 3,
  [DERIWTESTNET]: 3
};

export const RPC_PROVIDERS = {
  [MAINNET]: [
    "https://eth.llamarpc.com",
    "https://eth-mainnet.public.blastapi.io",
    "https://core.gashawk.io/rpc"
  ],
  [TESTNET]: [
    "https://eth-sepolia.public.blastapi.io",
    "https://rpc-sepolia.rockx.com",
    "https://gateway.tenderly.co/public/sepolia"
  ],
  [ARBITRUM]: [
    "https://arbitrum.llamarpc.com",
    "https://rpc.arb1.arbitrum.gateway.fm",
    "https://arb-mainnet-public.unifra.io",
    "https://arbitrum.meowrpc.com"
  ],
  [ARBITRUMSEPOLIA]: [
    "https://ethereum-sepolia.core.chainstack.com/5c08f94be4128b3b8573648a2b8f561c",
    // "https://arbitrum-sepolia.infura.io/v3/2R6OUT2ufahOiBZuTjoCFyQDX6R",
    "https://arbitrum-sepolia.blockpi.network/v1/rpc/public ",
    "https://sepolia-rollup.arbitrum.io/rpc"
  ],
  [DERIWTESTNET]: [
    "https://rpc.test.deriw.com/"
  ],
  [DERIWDEVNET]: [
    "https://rpc.test.deriw.com/"
  ]

};

export const FALLBACK_PROVIDERS = {
};

export const NETWORK_METADATA = {
  [MAINNET]: {
    chainId: "0x" + MAINNET.toString(16),
    chainName: "Ethereum",
    nativeTokenSymbol: "ETH",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[MAINNET],
    blockExplorerUrls: ["https://etherscan.io/"],

    defaultCollateralSymbol: "USDT",
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
  [TESTNET]: {
    chainId: "0x" + TESTNET.toString(16),
    chainName: "Sepolia",
    nativeTokenSymbol: "ETH",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[TESTNET],
    blockExplorerUrls: ["https://sepolia.etherscan.io/"],

    defaultCollateralSymbol: "USDT",
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
  [ARBITRUM]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeTokenSymbol: "ETH",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUM],
    blockExplorerUrls: ["https://arbiscan.io/"],

    defaultCollateralSymbol: "USDT",
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
  [ARBITRUMSEPOLIA]: {
    chainId: "0x" + ARBITRUMSEPOLIA.toString(16),
    chainName: "Arbitrum Sepolia",
    nativeTokenSymbol: "ETH",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[ARBITRUMSEPOLIA],
    blockExplorerUrls: ["https://sepolia.arbiscan.io/"],

    defaultCollateralSymbol: "USDT",
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
  [DERIWTESTNET]: {
    chainId: "0x" + DERIWTESTNET.toString(16),
    chainName: "Deriw Testnet",
    nativeTokenSymbol: "Deriw",
    nativeCurrency: {
      name: "Deriw",
      symbol: "Deriw",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[DERIWTESTNET],
    blockExplorerUrls: ["https://explorer.test.deriw.com"],
    defaultCollateralSymbol: "USDT",
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
  },
  [DERIWDEVNET]: {
    chainId: "0x" + DERIWTESTNET.toString(16),
    chainName: "Deriw Testnet",
    nativeTokenSymbol: "Deriw",
    nativeCurrency: {
      name: "Deriw",
      symbol: "Deriw",
      decimals: 18,
    },
    rpcUrls: RPC_PROVIDERS[DERIWTESTNET],
    blockExplorerUrls: ["https://explorer.test.deriw.com"],
    defaultCollateralSymbol: "USDT",
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
    // chainId: "0x" + DERIWDEVNET.toString(16),
    // chainName: "Deriw Devnet",
    // nativeTokenSymbol: "Deriw",
    // nativeCurrency: {
    //   name: "Deriw",
    //   symbol: "Deriw",
    //   decimals: 18,
    // },
    // rpcUrls: RPC_PROVIDERS[DERIWDEVNET],
    // blockExplorerUrls: ["https://explorer.dev.deriw.com"],
    // defaultCollateralSymbol: "USDT",
    // SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
    // INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
    // DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0"),
  },


};

export const getConstant = (chainId, key) => {
  if (!NETWORK_METADATA[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in NETWORK_METADATA[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }
  const _key = key;

  return NETWORK_METADATA[chainId][_key];
};

export function getChainName(chainId) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getRpcUrl(chainId) {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}

const ICONS = {
  [MAINNET]: {
    network: ethIcon,
    network1: ethIcon,
  },
  [TESTNET]: {
    network: ethIcon,
    network1: ethIcon,
  },
  [ARBITRUM]: {
    network: arbIcon,
    network1: arbIcon,
  },
  [ARBITRUMSEPOLIA]: {
    network: arbIcon,
    network1: arbIcon,
  },
  [DERIWTESTNET]: {
    network: deriwIcon,
    network1: deriwIcon,
  },
  [DERIWDEVNET]: {
    network: deriwIcon,
    network1: deriwIcon,
  },
  common: {
    network: network,
    network1: network
  },
};

export function getIcon(chainId, label) {
  if (chainId == 0) {
    return ICONS.common[label]
  }
  if (chainId in ICONS) {
    if (label in ICONS[chainId]) {
      return ICONS[chainId][label];
    }
  }
}

export function getFallbackRpcUrl(chainId) {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

export function getIcons(chainId) {
  if (chainId == 0) {
    return ICONS[chainId]
  }
  if (!chainId) return;
  if (chainId in ICONS) {
    return ICONS[chainId];
  }
}

// export type NetowrkOptionStruct = {
//   label: string,
//   value: number,
//   icon: string|undefined,
//   color: string
// }
//const NETWORK_OPTIONS: NetowrkOptionStruct[] 
export const NETWORK_OPTIONS = config.isDevelopment() ? [
  {
    label: getChainName(DERIWDEVNET),
    value: DERIWDEVNET,
    icon: getIcon(DERIWDEVNET, "network"),
    color: "#E841424D",
  },
] : config.isTestnet() ? [
  {
    label: getChainName(DERIWTESTNET),
    value: DERIWTESTNET,
    icon: getIcon(DERIWTESTNET, "network"),
    color: "#E841424D",
  }
] : [];


export const NETWORK_OPTIONS_CROSS = config.isDevelopment() ? [
  {
    label: getChainName(TESTNET),
    value: TESTNET,
    icon: getIcon(TESTNET, "network"),
    color: "#E841424D",
  },
  {
    label: getChainName(ARBITRUMSEPOLIA),
    value: ARBITRUMSEPOLIA,
    icon: getIcon(ARBITRUMSEPOLIA, "network"),
    color: "#E841424D",
  },
  {
    label: getChainName(DERIWDEVNET),
    value: DERIWDEVNET,
    icon: getIcon(DERIWDEVNET, "network"),
    color: "#E841424D",
  },
] : config.isTestnet() ? [
  {
    label: getChainName(TESTNET),
    value: TESTNET,
    icon: getIcon(TESTNET, "network"),
    color: "#E841424D",
  },
  {
    label: getChainName(ARBITRUMSEPOLIA),
    value: ARBITRUMSEPOLIA,
    icon: getIcon(ARBITRUMSEPOLIA, "network"),
    color: "#E841424D",
  },
  {
    label: getChainName(DERIWTESTNET),
    value: DERIWTESTNET,
    icon: getIcon(DERIWTESTNET, "network"),
    color: "#E841424D",
  },
] : [];