import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

let lang = navigator.language;
if (!localStorage.getItem("i18nextLng")) {
  // lang = lang.substr(0, 2) === "zh" ? "ZH" : lang.substr(0, 2);
  lang = "EN";
} else {
  lang = String(localStorage.getItem("i18nextLng"));
}

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: lang,
    // lowerCaseLng: true,
    backend: {
      // loadPath: `./locales/en.json`,
      // loadPath: `./locales/${lang}.json`
      loadPath: `/locales/{{lng}}.json?timestamp=3.2`
      // loadPath: `./locales/{{lng}}.json?timestamp=2.6` // 47用
    },
    react: {
      useSuspense: true
    },
    fallbackLng: "EN",
    // preload: ["EN", "ZH", "JA", "KO", "RU", "TH", "VI", "IT"],
    keySeparator: false,
    interpolation: { escapeValue: false }
  });

export default i18next;
