import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 35318034165, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://testgmxapi.weequan.cyou",
  testTransactionUrl: "https://dev.deriw.com/trading-prop/trade",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/trade",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://testgmxapi.weequan.cyou/ws",
  explorer: "http://explorer.dev.deriw.com/",
  tx: 'http://explorer.dev.deriw.com/tx/',
  deriwExplorer: "https://explorer.deriw.com/",
  deriwTx: 'https://explorer.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://twitter.com/DeriwFi",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",

  ethRpc: "https://rpc.dev.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复
  
  Vault: "0xcA6309d7F093D0FDBA3a366eDFceCAB8797ec121",
  Router: "0x29df3C6588Dc0577a2660D547ECeFf10f65832c1",
  VaultUtils: "0x4F0c38B5312f14f59FF077325f4f2f86d56Ae6C4",
  VaultReader: "0xc1c7Ff8780235B577121Ea38e888e921ea8f62FC",
  Reader: "0xC884C88d463d113DE90Be13d9F8233986c1c1FD3",
  GlpManager: "0x49B350719908231e08747e40195fb2947984d8FD",
  GlpRewardRouter: "0xa9E3d7E82fac19601f022De323ec8282702751d2",
  NATIVE_TOKEN: "0xb2CCe9bDe4177B5A39a6ade5f3881Cb65D447D93", // (WETH)
  GLP: "0x379Ad18760091c331AF47e849f526D17704AB636",
  OrderBook: "0xb3B6aE96874eb964FEAFDAF5Fd1a2Eb634762C7A",
  OrderBookReader: "0x9274Bb20655Adb67a9816f74e2BA6821689071d8",

  PositionRouter: "0xD44b023B485F5f2E19b5AC0b6b03A6ecC9851E89",
  ReferralStorage: "0xa1174D2065eDfFDd58ba918e77db5459A1A6f577",
  ReferralData: "0x7991CebA70289150E84dc3AF48D1F8Fa5aE21c91",
  ReferralReader: "0xfcd5ddd1B944910b94Dc232eA68b6bcd33D5c422",
  Timelock: "0xf67334BC17eC21eE87b5068510B92170A6EbAa56",
  Slippage: "0xB764506EA242571Ff1E06595537DCeaF6d4a250a",

  Phase: "0xDc734B0ba3fc0d683490b2b23e4E527F89b7ef0C",
  AuthV2: "0xC734a1e4f8Bf6eb2a84C9bAD0271B4Ca2Ad8721e",
  FoundFactoryV2: "0x8A8947D8AE902Ee0b71F6711ac5945912B614775",
  PoolDataV2: "0x6AC524ab5b939608253fd02FeF4985135A0E211D",
  LpCounter: "0x095e27DED5b9F30ABdD97964e570B2366cB8b3B1",
  ErrorContractV2: "0x7b996B15A41455D124C5bfd8204028D6CF0905b9",
  FoundRouterV2: "0x78a1B50E6edF75C3a5C03C7B93CEDd1FcB42DB0E",
  FoundReader: "0x1717fbc7b418e43A3E9892827c068e09e2228444",
  // 测试币领取
  MINT_AIRDROP: "0x7a54F54c40Aa0481303f35fD96a59341fABC7187",
  // Meme资金池
  MemeData: "0x0ADEdc6288852631d35A4D2C596312Ad4CCF40Bd",
  MemeErrorContract: "0x97EEB61ee4d18Ba08138de710B4046a3C7ecCfFF",
  MemeFactory: "0x220bdaF8A07Bb28ca7696A8B4ccfDE53D85E7e18",
  MemeRouter: "0xD932C2258e143d96D3e7f913b6D3b7dc9f2D8Be5",

  // Hyper 
  DeriwSubAccountPublic: "0x00000000000000000000000000000000000007E9",


  TestAuth: "0x6e6F13D2706D861F539925A5Af18893FB3D1D79C",
  TestPrpoFactory: "0xE9F045f0CE5dc1AD552e20E8df668194d67f95D5",
  TestPropTradeData: "0x305507D45D5441B81F5dD8FF9f00f65e0B392e86",
  TestErrContract: "0x3D343Fc0F6c6D2E047ec5e16e39A9b6A2031B9Ac",
  TestPropTradeRouter: "0x4D778dE09f5C043677bd18888114A9a0911dCE96",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003
  }
};
